.symptomvaluelist {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.symptomvaluebox {
    border-radius: 0.125rem;
    background: #ffffff22;
    color: white;
    padding: 0.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    user-select: none;
    display: flex;
    pointer-events: auto;

    input, select {
        background: #ffffff22;
    }
    
    select {
        outline: none;
    }
}

option {
    background: var(--mirno-dark);
    color: white;
}

.symptomvaluebox:hover {
    background: #ffffff33;
}