@import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

h1 {
  font-weight: bold;
  font-size: medium;
}

section.cs-message.system {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

@keyframes scale-up {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

section.cs-message {
  transform-origin: left;
  
  &.user {
    animation: scale-up 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 0;
    transform-origin: right;

    .cs-message__content {
      border-radius: 0.5rem;
      background-color: var(--mirno-purple);
      font-family: "Lato", sans-serif;
      color: var(--mirno-light);
      border-top-right-radius: 0;
    }

    *>a {
      color: var(--mirno-green);
    }
  }

  &.assistant, &.ui {
    animation: scale-up 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    justify-content: flex-start;
    margin-left: 0;
    margin-right: auto;

    .cs-message__content {
      border-radius: 0.5rem;
      background-color: var(--mirno-light);
      font-family: "Lato", sans-serif;
      border-top-left-radius: 0;
    }
  }

  &.tool-call,
  &.tool-result {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.5;

    .cs-message__content {
      border-radius: 0.5rem;
      background-color: #f0f0f0;
      font-family: "Lato", sans-serif;
    }

    * {
      font-family: 'Courier New', Courier, monospace !important;
      font-size: small;
    }
  }

  *>a {
    color: var(--mirno-accent-purple);
  }
}

section.cs-message.system .cs-message__content {
  border-radius: 0.5rem;
  background-color: aquamarine;
  font-family: "Lato", sans-serif;
}

.hide-tool-system .tool-call,
.hide-tool-system .system,
.hide-tool-system .tool-result {
  display: none;
}

.cs-main-container {
  border: none;
}

.cs-chat-container,
.cs-message-list,
.cs-message-list__scroll-wrapper {
  background: var(--mirno-dark) !important;
}

.cs-chat-container .cs-message-input {
  background: var(--mirno-dark) !important;
  border: none;
}

.cs-message-input__content-editor,
.cs-message-input__content-editor-wrapper {
  background: var(--mirno-light);
}

.cs-button--send {
  color: var(--mirno-green);
}

.cs-message-list .cs-typing-indicator {
  background-color: #f0f0f000;

  .cs-typing-indicator__indicator {
    & div {
      background-color: var(--mirno-green);
    }
  }

  .cs-typing-indicator__text {
    color: var(--mirno-green);
  }
}

.cs-typing-indicator__dot {
  animation: cs-typing-indicator__typing-animation 1s infinite ease-in-out;
}

.cs-typing-indicator__dot:nth-child(2) {
  animation-delay: 100ms;
}

.cs-typing-indicator__dot:nth-child(3) {
  animation-delay: 200ms;
}

.progressBar {
  background-color: #ffffff88;
  height: 0.5rem !important;
  border-radius: 50px;
  width: 100%;
  overflow: hidden;

  div {
    background-color: #00000000 !important;
    height: 100% !important;
      div {
      background: var(--mirno-green) !important;
      text-wrap: nowrap;
      padding-right: 0.5rem;
    }
  }
}


.userLabel {
  color: white;
  opacity: 0.5;
  font-family: "Lato", sans-serif;
  font-size: small;
  padding-top: 0.5rem;

  &.You {
    text-align: right;
  }
}