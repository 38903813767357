:root {
    --mirno-dark: #202e30;
    --mirno-light: #ffffff;
    --mirno-green: #00ff99;
    --mirno-green-transparent: #00ff99aa;
    --mirno-purple: #cc99ff;
    --mirno-accent-blue: #66ffff;
    --mirno-accent-pink: #ffccff;
    --mirno-accent-navy: #0066ff;
    --mirno-accent-red: #ff3366;
    --mirno-accent-purple: #9966ff;
    --mirno-accent-green: #ccff99;
  }
  