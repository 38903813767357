.custom-slider {
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 0.5rem;
    cursor: pointer;
    position: relative;
}

.custom-slider::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    height: 0.5rem; /* Adjusted height for thinner appearance */
    background: #ddd;
    border-radius: 0.5rem;
}

.custom-track {
    border-radius: 0.5rem;
    top: 0;
    bottom: 0;
    position: relative;
    height: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
}

.custom-track.custom-track-0 {
    background: #7F56D9;

}

.custom-thumb {
    height: 0.5rem;
    width: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
}

.custom-thumb:focus {
    outline: none;
}


.custom-thumb {
    height: 0.5rem;
    width: 0.5rem;
    cursor: pointer;
    border-radius: 0.5rem;
    position: relative;
    z-index: 2;
    outline: none;
}

/* 
.custom-thumb:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: transparent;
    z-index: -1;
    background: #ff000088;
} */
