.custom-toggle {
    width: 2rem;
    height: 1rem;
    background-color: #ddd;
    border-radius: 1.2rem;
    cursor: pointer;
    position: relative;
    transition: background-color 0.2s;
}

.toggle-thumb {
    position: absolute;
    top: 0.1rem;
    bottom: 0.1rem;
    left: 0.1rem;
    width: 0.8rem;
    height: 0.8rem;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
}

.toggle-on {
    background-color: #7F56D9;
}

.thumb-on {
    transform: translateX(1rem);
    background-color: white;
}

.thumb-off {
    background-color: white;
}

.custom-toggle:hover .toggle-thumb {
    box-shadow: 0 0 5px #aaa;
}

.custom-toggle:focus .toggle-thumb, .custom-toggle:active .toggle-thumb {
    outline: none;
    box-shadow: 0 0 5px #aaa;
}
