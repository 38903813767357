.lung-sound-select {
    background: #7F56D9;
}

.lung-sound-select option {
    background: white; /* Override for options, setting them to have a white background */
    color: #222;
}

.lungs-image {
    fill: #ddd;
}