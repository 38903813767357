.demoButton {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;

  background: #ffffff11;
}

.demoButton--highlighted {
  outline: 1px solid white;
  background-color: var(--mirno-green-transparent);
}

.demoButton:hover {
  background-color: var(--mirno-green-transparent);
  box-shadow: 0px 20px 20px -15px rgba(0, 0, 0, 0.2);
}

.demoButton:active {
  background-color: var(--mirno-green-transparent);
  transform: scale(0.99);
}