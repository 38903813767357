input, select, textarea {
  font-size: 16px;
}

input, select, textarea {
  font-size: 16px;
}

.mode-button {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 0.25rem;
  gap: 0.25rem;

  width: 100%;

  background-color: var(--mirno-purple);
  color: var(--mirno-light);

  box-shadow: 0px 20px 20px -15px #000000;
  border-radius: 0.75rem;
  border-top-left-radius: 0px;

  font-family: "Hanken Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

  font-size: 16px;
  line-height: 19px;
  display: flex;
  padding: 1rem;
  letter-spacing: 0.08em;
  padding-right: 2rem;

  text-transform: uppercase;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;

  cursor: pointer;
  pointer-events: all;
}

.mode-button:hover {
  background-color: var(--mirno-green);
  color: var(--mirno-dark);
}


.mirnoMenuButton {
  padding: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  /* width: 100%; */
  text-align: left;

  background-color: var(--mirno-purple);
  color: var(--mirno-light);

  border-radius: 0.5rem;
  border-top-left-radius: 0px;

  font-family: "Hanken Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

  font-size: 12px;
  letter-spacing: 0.08em;

  transition: all 0.1s ease-in-out;

  cursor: pointer;
  pointer-events: all;

  &:hover {
    background-color: var(--mirno-green);
    color: var(--mirno-dark);
  }

  &[data-active] {
    background-color: var(--mirno-green);
    color: var(--mirno-dark);
  }
}


